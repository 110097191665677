@import "./fonts.css";
@import "./prose.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .box-shadow-sm {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  }

  .box-shadow-md {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }

  .box-shadow-lg {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
}


/**
 * Any updates to colors here should also be made in lib/colors/getHexColor() and tailwind.config.js
 */
@layer base {
  :root {
    /** Colors **/
    --accent-red: #F01849;
    --complimentary: #FFF;
    --tertiary: #F0E318;
    --light: #FBFBFB;
    --gray: rgb(209 213 219);
    --dark-gray: #9CA3AF;
    --white: #FFF;
    --bio-red: #E73227;
    --black: #333;
    --pitch-black: hsl(0, 0%, 0%);
    --lightgrey: hsl(220, 13%, 91%);
    --background: hsl(0, 0%, 97%);
    --foreground: hsl(224, 71.4%, 4.1%);
    --card: hsl(0, 0%, 100%);
    --card-foreground: hsl(224, 71.4%, 4.1%);
    --popover: hsl(0, 0%, 100%);
    --popover-foreground: hsl(224, 71.4%, 4.1%);
    --primary: hsl(196, 100%, 47%);
    --primary-foreground: hsl(210, 20%, 98%);
    --secondary: hsl(31, 100%, 47%);
    --secondary-foreground: hsl(220.9, 39.3%, 11%);
    --muted: hsl(220, 14.3%, 95.9%);
    --muted-foreground: hsl(220, 8.9%, 46.1%);
    --accent: hsl(220, 14.3%, 95.9%);
    --accent-foreground: hsl(220.9, 39.3%, 11%);
    --destructive: hsl(346, 88%, 52%);
    --destructive-foreground: hsl(210, 20%, 98%);
    --border: hsl(220, 13%, 91%);
    --input: hsl(220, 13%, 91%);
    --ring: hsl(196, 100%, 47%);
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;

    .dark {
      --background: 224 71.4% 4.1%;
      --foreground: 210 20% 98%;
      --card: 224 71.4% 4.1%;
      --card-foreground: 210 20% 98%;
      --popover: 224 71.4% 4.1%;
      --popover-foreground: 210 20% 98%;
      --primary: 210 20% 98%;
      --primary-foreground: 220.9 39.3% 11%;
      --secondary: 215 27.9% 16.9%;
      --secondary-foreground: 210 20% 98%;
      --muted: 215 27.9% 16.9%;
      --muted-foreground: 217.9 10.6% 64.9%;
      --accent: 215 27.9% 16.9%;
      --accent-foreground: 210 20% 98%;
      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 20% 98%;
      --border: 215 27.9% 16.9%;
      --input: 215 27.9% 16.9%;
      --ring: 216 12.2% 83.9%;
      --chart-1: 220 70% 50%;
      --chart-2: 160 60% 45%;
      --chart-3: 30 80% 55%;
      --chart-4: 280 65% 60%;
      --chart-5: 340 75% 55%;
    }
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  ._page_wrapper {
    @apply container h-full pb-4 pt-3 lg:pt-6;
  }
  ._component_bg {
    @apply bg-white p-8 md:p-16 rounded-lg shadow-sm;
  }
  ._frosted_glass {
    @apply border border-white bg-gradient-to-br from-white/80 to-white/20 backdrop-blur-md;
  }
  ._frosted_glass_dark {
    @apply border border-white bg-gradient-to-br from-gray-200/60 to-white/40 backdrop-blur-md;
  }
  ._text_shadow_dark {
    text-shadow: 0 2px 3px #919191
  }
  h1,
  h1 p {
    @apply pb-3 text-3xl text-black font-semibold md:pb-6 md:text-4xl lg:text-5xl lg:leading-[3.5rem];
  }
  h2,
  h2 p {
    @apply pb-3 text-2xl text-black font-bold md:text-3xl lg:text-4xl;
  }
  h3,
  h3 p {
    @apply pb-2 text-xl text-black font-bold md:text-2xl lg:text-3xl;
  }
  h4,
  h4 p {
    @apply pb-2 text-lg text-black font-bold md:text-xl lg:text-2xl;
  }
  h5,
  h5 p {
    @apply pb-2 text-lg text-black font-bold md:text-lg lg:text-xl;
  }
  h6,
  h6 p {
    @apply pb-2 text-lg text-black font-bold lg:text-lg;
  }
  p {
    @apply font-light leading-relaxed text-base md:text-lg lg:text-xl lg:leading-8;
  }
  .main-nav-item p {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
  .video-block-text p {
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
}

/* Prevent the Google Translate script from adding top. */
body {
  top: 0 !important;
}
/* Hide the Google Translate widget. */
body > .skiptranslate {
  display: none;
}

.formatted-text a {
  color: var(--primary) !important;
}

.visually-hidden {
  display: none;
}

.bg-dotted {
  background-image: radial-gradient(#15b8a63d 10%, transparent 10%);
  background-size: 25px 25px;
  background-position: 0 0, 30px 30px;
  background-repeat: repeat;
}

.slick-slide {
  margin-left: .5rem;
  margin-right: .5rem;
  height: inherit !important;
  padding-bottom: 10px;
}

.slick-slide > div {
  height: 100%;
}

.slick-track {
  display: flex;
  min-width: 100% !important;
}

.article-card:hover img,
.article-teaser:hover img {
  transform: scale(1.05);
}

.scale-image-hover:hover img {
  transform: scale(1.07) !important;
}

p {
  margin-bottom: .5rem;
}

.testing-method-card p {
  line-height: 1.5rem;
}

p a {
  color: var(--primary);
}

#nprogress {
  z-index: 30;
}
#nprogress .spinner {
  display: none !important;
}
#nprogress .peg {
  box-shadow: none !important;
}

img {
  transition: 1s;
}

.product-hero-body strong {
  font-weight: bold;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--primary);
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

::-moz-selection { /* Code for Firefox */
  color: var(--white);
  background: var(--primary);
}

::selection {
  color: var(--white);
  background: var(--primary);
}

@media screen and (min-width: 1920px) {
  .animated-hero canvas {
    min-height: 1080px;
    min-width: 1920px;
  }
}

html, body, * {
  font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.hygiena-main {
  min-height: 70vh;
}

.product-category-teaser p {
  margin: 0;
}

@keyframes spinner-rotate-outer {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spinner-rotate-inner {
  from {
    transform: rotate(-15deg);
  }
  to {
    transform: rotate(15deg);
  }
}

#hygiena-spinner_svg__inner {
  transform-origin: center;
  animation: spinner-rotate-inner 1s infinite alternate;
}

#hygiena-spinner_svg__outer {
  /*transform-box: fill-box;*/
  transform-origin: center;
  animation: spinner-rotate-outer 2s infinite linear;
}

.product-modal-image figure,
.product-modal-image figure > div {
  max-height: 100% !important;
  height: 100% !important;
}
.product-modal-image figure > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-modal-image figure > div img {
  max-height: 100% !important;
}
.submenu-image-card p {
  display: inline;
  margin: 0;
  padding: 0;
}

*:focus {
  outline-color: var(--primary);
}

.header-search-results p {
  display: inline;
}

.menu-italic p {
  margin: 0;
  padding: 0;
  display: inline;
  line-height: unset;
}

.react-player--video-block iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: fill;
  transform: translate(-50%, -50%);
  width: 100% !important;
  height: 100% !important;

  /*@media screen and (min-aspect-ratio: 16/9) {*/
  /*  width: 177.78vh;*/
  /*}*/

  /*@media screen and (max-aspect-ratio: 16/9) {*/
  /*  height: 56.25vw;*/
  /*}*/
}

@media screen and (max-width: 768px) {
  .iiz--product-image > div {
    display: flex;
  }
  .iiz--product-image > div img.iiz__img {
    object-fit: contain;
  }
}

.overflow-ellipsis:hover {
  transition: all .5s ease-in-out;
  word-break: break-word;
}
.overflow-ellipsis:not(:hover) {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: all .5s ease-in-out;
}

.grecaptcha-badge {
  display: none !important;
}

.menu-nav-pad:after {
  content: '';
  height: 1px;
  width: 50px;
  background: var(--dark-gray);
  position: absolute;
  margin: auto;
  top: 100px; left: 0; right: 0; bottom: 0;
}
.menu-nav-pad:before {
  content: '';
  height: 50px;
  width: 1px;
  background: var(--dark-gray);
  position: absolute;
  margin: auto;
  top: 100px; left: 0; right: 0; bottom: 0;
}
select option:disabled {
  color: #E5E7EB;
}
.slick-list {
  overflow: hidden;
}

.scroll-primary:not(:hover)::-webkit-scrollbar {
  display: none;
}
.scroll-primary::-webkit-scrollbar {
  width: 8px;
}

.scroll-primary::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-primary::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 5px;
}

.scroll-primary::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.scroll-primary::-webkit-scrollbar:horizontal {
  height:0;
  display: none;
}

.scroll-primary::-webkit-scrollbar-button:decrement,
.scroll-primary::-webkit-scrollbar-button:increment {
  width: 0;
}

/* Hide google translate. */
#goog-gt-tt {
  display: none !important;
}
.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.alert-banner p {
  margin-bottom: 0;
}
.alert-banner p a {
  color: var(--white);
}
.paragraph--featured-solutions-slider .slick-track {
  margin-bottom: 1rem;
}
.origin-50-50 {
  transform-origin: 50% 50%;
}
.section-heading :is(h2, h3, h4, h5, h6) strong {
  color: var(--primary);
}
.grid-col-3 {
  grid-column: auto / span 3;
}
.grid-col-4 {
  grid-column: auto / span 4;
}
.grid-col-5 {
  grid-column: auto / span 5;
}

/* Styles for Image Aside CTA component */
.layout-section ul li {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.layout-section ul a {
  color: var(--primary) !important;
  text-decoration: none !important;
  font-weight: 600;
}

/* AO Forms */
textarea.ao-form-field.ao-form-error + .ao-form-error-message {
  visibility: visible !important;
}